import viewImg from "../../img/icons/view.svg";
import Scroll from "react-scroll";

function MainSection() {
  const scroll = Scroll.animateScroll;

  return (
    <section className="main-section">
      <h1 className="main-logo">
        <span>Wrapped</span> Zano
      </h1>
      <p className="main-info">
        Wrapped Zano (WZANO) is an ERC-20 token on the Ethereum blockchain that
        can be exchanged for ZANO on a 1:1 basis at any time. One of the most
        important considerations of this initiative is transparency. For every
        WZANO in circulation (i.e. not in the deployment wallet), there will be
        at least the same amount or more ZANO in the team’s custodial wallet.
      </p>
      <div className="btns-container">
        <a href="https://etherscan.io/token/0xdb85f6685950e285b1e611037bebe5b34e2b7d78">
          <button className="btn">Smart contract</button>
        </a>
        <button
          onClick={() => {
            scroll.scrollTo(600);
          }}
          className="btn"
        >
          Proof of assets
        </button>
        <a href="/app" className="btn-border">
          <button className="btn bordered-btn">
            <img src={viewImg} alt="" />
            Open app
          </button>
        </a>
      </div>
    </section>
  );
}
export default MainSection;
