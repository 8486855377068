import twitter from '../img/icons/twitter.svg';
import medium from '../img/icons/medium.svg';
import discord from '../img/icons/discord.svg';
import telegram from '../img/icons/telegram.svg';
import delta from '../img/icons/delta.svg';
import blockfolio from '../img/icons/blockfolio.svg';
function SocialList() {
    return (
      <div className="social-container">
        <ul className="social-list">
          <li><a href="https://twitter.com/zano_project"><img src={twitter} alt="socialList-item" /></a></li>
          <li><a href="https://medium.com/zano-news"><img src={medium} alt="socialList-item" /></a></li>
          <li><a href="https://discord.gg/wE3rmYY"><img src={discord} alt="socialList-item" /></a></li>
          <li><a href="https://t.me/zanocoin"><img src={telegram} alt="socialList-item" /></a></li>
          <li><a href="https://delta.app/en/crypto/zano/zano"><img src={delta} alt="socialList-item" /></a></li>
          <li><a href="https://blockfolio.com/coin/ZANO"><img src={blockfolio} alt="socialList-item" /></a></li>
        </ul>
      </div>
    );
}
  
export default SocialList;