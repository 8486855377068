import MainSection from './sections/MainSection';
import CirculationSection from './sections/CirculationSection';
import AssetsSection from './sections/AssetsSection';
import FAQSection from './sections/FAQSection';
function Main() {
    return (
        <main>
            <h1 className="background-logo">WRAPPED</h1>
            <MainSection/>
            <AssetsSection/>
            <h1 className="background-logo">FAQ</h1>
            <h1 className="main-logo faq"><span>What is WZANO/FAQ</span></h1>
            <FAQSection/>
        </main>
    );
}
export default Main;