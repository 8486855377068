function Asset(props) {
  const handleCopy = (e) => {
    e.preventDefault();
    navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
      if (result.state === "granted" || result.state === "prompt") {
        navigator.clipboard.writeText(
          "aZxb34VSFPwPJMHReeD4FEhrkUtCgZPQ3aXpMkgFuw6sdSWtph298W55Tn68pkRVFKEx2Kq7rKxyuSqntY8BDrRm8fxyMzVjyYj:4005f94d8efbffb05b75b772d0efc39dc6846b941c2bb6d386a91d0eafde090c:1630585006"
        );
      } else {
        alert("You need to grant permission to copy");
      }
    });
  };

  return (
    <div className="tr">
      <div className="td">
        <span className="address">{props.address}</span>
        <a href="/" className="copy" onClick={handleCopy}>
          Copy tracking seed
        </a>
      </div>
      <div className="td">
        <span className="balance">{props.unwrap}</span>
      </div>
      <div className="td">
        <span className="balance">{props.balance.toFixed(2)}</span>
      </div>
    </div>
  );
}
export default Asset;
