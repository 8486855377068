import Scroll from "react-scroll";

function HeaderMenu() {
  const scroll = Scroll.animateScroll;

  return (
    <div className="header-menu">
      <nav>
        <ul className="menu">
          <li>
            <a style={{ cursor: "pointer" }}>
              <span
                onClick={() => {
                  scroll.scrollTo(600);
                }}
              >
                DASHBOARD
              </span>
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }}>
              <span
                onClick={() => {
                  scroll.scrollTo(900);
                }}
              >
                FAQ
              </span>
            </a>
          </li>
          <li>
            <a href="https://zano.org">
              <span>ZANO.ORG</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
export default HeaderMenu;
