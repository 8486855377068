import FAQ from "./FAQ";
function FAQSection() {
  let data = [
    {
      question: "What is Wrapped Zano?",
      answer:
        "Wrapped Zano (WZANO) is an ERC-20 token on the Ethereum blockchain that can be swapped 1:1 for ZANO.",
    },
    {
      question: "What is the purpose of Wrapped Zano?",
      answer:
        "It allows us access to the enormous existing infrastructure that exists around Ethereum and ERC-20 tokens: DEX such as Uniswap, multi-token wallets like Trust Wallet, hardware wallets etc. We may also see WZANO listed by centralized exchanges who are concerned about the regulatory issues that may arise from listing a surveillance-proof currency like ZANO directly.",
    },
    {
      question: "How do I exchange ZANO for WZANO?",
      answer:
        "The process of swapping ZANO to WZANO is referred to as “wrapping”. You can wrap ZANO from within any of the Zano mobile or desktop clients. Just go to the “Send” tab, enter the amount of ZANO you would like to receive and the Ethereum address to which you’d like the WZANO to be sent. You will be informed of how much Zano is required to cover the transaction fees before you finalize the transaction.",
    },
    {
      question: "How do I unwrap WZANO?",
      answer:
        "Follow to the app link on the website above, connect metamask and specify Zano address to which you want to receive ZANO. After that, you will be able to unwrap WZANO to ZANO. The process takes around 15 minutes and you will receive ZANO to your Zano address.",
    },
    {
      question: "How are fees calculated?",
      answer:
        "An estimate of the gas required for the transaction is made, then we get the current Ethereum and Zano USD prices and use those to calculate how much Zano is needed at current prices to cover the gas fee.",
    },
    {
      question: "Will the ZANO in the custodian wallet be staked?",
      answer:
        "Yes. This will add to the total number of coins securing the network through proof of stake and thus will increase overall network security. Any rewards earned will go towards the infrastructural and maintenance costs of the wrapping and custodial service. ",
    },
    {
      question:
        "Do I already need to have ethereum balance in order to get WZANO?",
      answer:
        "No.  In order to get WZANO all you need to have is a zano wallet with a balance and an ethereum address.  You just need to send ZANO directly within the wallet to your ETH address and the infrastructure will take care of the rest.  You will receive the equivalent of your transfer in WZANO minus the zano and ethereum transaction fees.",
    },
  ];
  return (
    <section className="faq-section">
      <div className="faq-list-container">
        <FAQ data={data} />
      </div>
    </section>
  );
}
export default FAQSection;
