import { useRef, useState } from 'react';
import Logo from './Logo';
import HeaderMenu from './HeaderMenu';
import SocialList from './SocialList';
function Header() {
    let firstLine = useRef(null);
    let lastLine = useRef(null);
    let menuWindow = useRef(null);
    let [isMenuOpen, changeMenuState] = useState(false);
    function menu() {
        let newState = isMenuOpen ? false : true;
        changeMenuState(newState);
        if (!isMenuOpen) {
            firstLine.current.style.transform = 'translate(0, 250%)';
            lastLine.current.style.transform = 'translate(0, -250%)';
            menuWindow.current.style.height = 'calc(100vh - 90px)';
        }else{
            firstLine.current.style.transform = null;
            lastLine.current.style.transform = null;
            menuWindow.current.style.height = 0;
        }
    }
    return (
        <header className="header-container">
            <div className="header-content">
                <Logo/>
                <HeaderMenu/>
                <div className="mobile-menu" onClick = {menu}>
                    <div className="modile-menu-line" ref={firstLine}></div>
                    <div className="modile-menu-line"></div>
                    <div className="modile-menu-line" ref={lastLine}></div>
                </div>
                <div className="mobile-menu-window" ref={menuWindow}>
                    <HeaderMenu/>
                </div>
                <SocialList/>
            </div>  
        </header>
    );
}
export default Header;