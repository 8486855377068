import { useEffect, useRef } from "react";
function CirculationSection() {
    useEffect(() => {
        const values = [
            0, 10000
        ];
        let maxDataValue = Math.max(...values)*2;
        console.log(maxDataValue);
        let maxValue = Math.ceil(maxDataValue/ 10 ** (maxDataValue.toString().length-1)) * 10 ** (maxDataValue.toString().length-1)*0.7;
        function createCanvas(w, h) {
            let ratio = window.devicePixelRatio;
            let canvas = canvasRef.current;
            canvas.width = w * ratio;
            canvas.height = h * ratio;
            canvas.style.width = w + "px";
            canvas.style.height = h + "px";
            canvas.getContext("2d").scale(ratio, ratio);
            return canvas;
        }
        let canvas = createCanvas(canvasContainer.current.getBoundingClientRect().width, 500);
        let context = canvas.getContext("2d");
        let points = createGraph();
        let isPopupDrawn = false;
        if (window.screen.width > 500) {
            canvas.addEventListener('mousemove', (e) => checkPopup(e));
        }
        window.addEventListener('resize', () => {
            canvas = createCanvas(canvasContainer.current.getBoundingClientRect().width, 500);
            points = createGraph();
            if (window.screen.width > 500) {
                canvas.addEventListener('mousemove', (e) => checkPopup(e));
            }
        });

        function checkPopup(e) {
            let mousePoint = {x: null, y: null}
            let isMouseonPoint = false;
            let amount = null;
            for (let point = 0; point < points.length; point++) {
                if (Math.abs(parseInt(points[point].x.toFixed()) - parseInt(e.offsetX)) < 5 && Math.abs(parseInt(points[point].y.toFixed()) - parseInt(e.offsetY)) < 5) {
                    mousePoint.x = points[point].x.toFixed();
                    mousePoint.y = points[point].y.toFixed();
                    isMouseonPoint = true;
                    amount = values[point];
                }else if(isMouseonPoint === false){
                    mousePoint.x = null;
                }
            }
            if (mousePoint.x !== null) {
                popup(mousePoint.x, mousePoint.y, amount);
            } else if (isPopupDrawn === true){
                isPopupDrawn = false;
                canvas = createCanvas(canvasContainer.current.getBoundingClientRect().width, 500);
                createGraph();
            }
        }
        function drawTriangle(x, y, type) {
            context.beginPath();
            if (type === null) {
                context.moveTo(x,y);
                context.lineTo(x-10,y-10);
                context.lineTo(x+10,y-10);
                context.lineTo(x,y);
            } else {
                y = y+5;
                x = x+5;
                context.moveTo(x,y);
                context.lineTo(x+20,y-10);
                context.lineTo(x+20,y+10);
                context.lineTo(x,y);
            }
            context.stroke();
            context.fill()
            context.closePath();
        }
        function drawRectangle(x, y, popupRectWidth, popupRectHeight, type) {
            if (type !== null) {
                context.beginPath();
                context.rect(x+25, y-popupRectHeight/2-5, popupRectWidth, popupRectHeight);
                context.fill();
                context.stroke();
            }else{
                context.beginPath();
                context.rect(x-popupRectWidth/2, y-popupRectHeight-17, popupRectWidth, popupRectHeight);
                context.fill();
                context.stroke();
            }
        }
        function drawData(x, y, type, amount) {
            context.fillStyle = "#9eaacc";
            context.font = "500 0.85em Roboto";
            if (type === null) {
                context.fillText("DATE", x-60, y-70);
                context.fillStyle = "white";
                context.fillText(amount, x-60, y-45);
                context.fillStyle = "#1796ff";
                context.fillText("WZANO", x+15, y-45);
            } else {
                context.fillText("DATE", x+45, y-20);
                context.fillStyle = "white";
                context.fillText(amount, x+45, y+5);
                context.fillStyle = "#1796ff";
                context.fillText("WZANO", x+120, y+5);
            }
        }
        function popup(x, y, amount) {
            let popupType = null; 
            if (x < 75 || canvasContainer.current.getBoundingClientRect().width < 600) {
                popupType = 'right';
            }
            if (isPopupDrawn === false) {
                x = parseInt(x);
                y = parseInt(y);
                context.strokeStyle = "#32439f";
                context.fillStyle = "#32439f";
                drawTriangle(x, y-7, popupType);
                drawRectangle(x, y, 150, 70, popupType);
                drawData(x, y, popupType, amount);
                isPopupDrawn = true;
            }
        }
        function createGraph() {
            let canvasWidth = canvasContainer.current.getBoundingClientRect().width;
            let canvasHeight = 500;
            let rectWidth = canvasWidth/12.2;
            let rectHeight = canvasHeight/2.8
            let padding = (canvasWidth - rectWidth*12)/2;
            context.lineWidth = 2;
            context.strokeStyle = "#1a1f3c";  
            for (let index = 0; index < 12; index++) {
                context.strokeRect(index*rectWidth+padding, 10, rectWidth, rectHeight); 
            }
            for (let index = 0; index < 12; index++) {
                context.strokeRect(index*rectWidth+padding, 10 + rectHeight, rectWidth, rectHeight); 
            }
            for (let index = 0; index < 13; index++) {
                context.beginPath();
                context.moveTo(index*rectWidth+padding, rectHeight*2);
                context.lineTo(index*rectWidth+padding, rectHeight*2+30);
                context.stroke();
            }
            context.font = "0.7em Verdana";
            context.fillStyle = "#9eaacc"; 
            context.fillText("0 WZANO", 10+padding, rectHeight*2.03);
            context.fillText(`${(maxValue/2).toFixed()} WZANO`, 10+padding, rectHeight*1.035);
            context.fillText(`${maxValue.toFixed()} WZANO`, 10+padding, 17);

            context.font = "10px Verdana";
            let textpadding = canvasContainer.current.getBoundingClientRect().width/70;
            if (canvasContainer.current.getBoundingClientRect().width > 870) {
                for (let index = 0; index < 12; index++) {
                    let month = (index+1) > 9 ? index+1 : `0${index + 1}`;
                    context.fillText(`01/${month}/2021`, textpadding+rectWidth*index, rectHeight*2.15); 
                }
            
            }else if(canvasContainer.current.getBoundingClientRect().width > 750) {
                context.font = "8px Verdana";
                for (let index = 0; index < 12; index++) {
                    let month = (index+1) > 9 ? index+1 : `0${index + 1}`;
                    context.fillText(`01/${month}/2021`, textpadding+rectWidth*index, rectHeight*2.15); 
                }
            }else{
                for (let index = 0; index < 12; index++) {
                    let month = (index+1) > 9 ? index+1 : `0${index + 1}`;
                    context.save();
                    context.rotate(-90 * (Math.PI / 180));
                    context.fillText(`01/${month}/2021`, -rectHeight*2.45, rectWidth + rectWidth*index-rectWidth/2); 
                    context.restore();
                }

            }

            const wzanoPrice = (canvasContainer.current.getBoundingClientRect().height-150)/maxValue;
            const points = [];
            for (let index = 0; index < values.length; index++) {
                points.push({x: padding + rectWidth/30*index, y: rectHeight*2+10 - values[index]*wzanoPrice});
            }
            context.lineWidth = 3;
            context.strokeStyle = "#2489ed"; 
            context.fillStyle = "#2489ed";
            function drawDot(x, y, width = 4) {
                context.beginPath();
                context.arc(x, y, width, 0, 2 * Math.PI, true);
                context.fill();
                context.closePath(); 
            }
            for (let index = 0; index < points.length; index++) {
                if (!points[index + 1]) {
                    drawDot(points[index].x, points[index].y);
                    break;
                }
                context.beginPath();
                context.moveTo(points[index].x, points[index].y);
                context.lineTo(points[index+1].x, points[index+1].y);
                context.stroke();   
                context.closePath(); 
                drawDot(points[index].x, points[index].y, 1);
                if (index === 0) {
                    drawDot(points[index].x, points[index].y);
                }
            }       
            return points;
        }
    });
    let canvasRef = useRef(null);
    let canvasContainer = useRef(null);
    return (
        <section className="circulation-section">
            <div className="circulation-container">
                <h3>Circulation supply: 10000 WZANO</h3>
                <div className="canvas-container" ref={canvasContainer}>
                    <canvas className="circulation-canvas" ref={canvasRef}>
                        Your browser doesn't support the chart element
                    </canvas>
                </div>
            </div>
        </section>
    );
}
export default CirculationSection;