import Asset from "./Asset";
import { useEffect, useState } from "react";

function AssetsSection() {
  useEffect(() => {
    getData();
  }, []);

  const [balance, setBalance] = useState(0);
  const [unwrap, setUnwrap] = useState(null);
  const getData = async () => {
    fetch("custody_balance.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log(json);
        setBalance(json);
      });
    fetch("unwrapped_balance.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log(json);
        setUnwrap((json / 1000000000000).toFixed(2));
      });
  };

  return (
    <section className="assets-section">
      <div className="assets-container">
        <h3>Custody/Proof of assets</h3>
        <div className="table">
          <div className="tr">
            <div className="th">ADDRESS</div>
            <div className="th">available</div>
            <div className="th">balance</div>
          </div>
          <Asset
            address={"aZxb34VSFPwPJMHReeD4FEhrkUtCgZ..."}
            balance={balance}
            unwrap={unwrap}
          />
        </div>
      </div>
    </section>
  );
}
export default AssetsSection;
