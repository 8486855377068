import Logo from "./Logo";
import HeaderMenu from "./HeaderMenu";
import SocialList from "./SocialList";
function Footer() {
  return (
    <footer>
      <div className="footer-container">
        <Logo onlyImg />
        <HeaderMenu />
        <SocialList />
        <p className="copyright">
          Copyright © {new Date().getFullYear()} ZANO.org
        </p>
      </div>
    </footer>
  );
}
export default Footer;
