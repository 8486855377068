import { useState } from 'react';
import FAQImg from '../../img/icons/faq.svg'
function FAQ(props) {
    let [active, changeActive] = useState(0);
    let result = [];
    for (let index = 0; index < props.data.length; index++) { 
        result.push(
            <div 
                className={`faq-item ${parseInt(active) === index ? 'faq-active' : ''}`} 
                key={index} 
                id={index}
                onClick={(e) => changeActive(e.currentTarget.id)}
            >
                <div className="faq-question">
                    <img className="faq-icon" src={FAQImg} alt="" />
                    <span>{props.data[index].question}</span>
                </div>
                <div className="faq-answer">
                    <p>{props.data[index].answer}</p>
                </div>
            </div>
        );
    }
    return ([result]);
}
export default FAQ;