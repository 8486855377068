import logoImg from "../img/logo_new.svg";
function Logo(props) {
  return (
    <>
      <div className="header-logo">
        <a href="/" className="logo-container">
          <img className="logo" src={logoImg} alt="logo" />
          {!props.onlyImg && (
            <div className="logo-text-container">
              <p className="logo-text">
                Zano<span>Wrapped</span>
              </p>
            </div>
          )}
        </a>
      </div>
    </>
  );
}
export default Logo;
